import React from "react"
import { graphql } from "gatsby"
import { RichText, Date } from "prismic-reactjs"
import { Facebook, Twitter } from 'react-social-sharing'
import { Disqus } from 'gatsby-plugin-disqus'

import HomeContainer from "../containers/HomeContainer"
import CTASection from "../sections/CTASection"
import HeadData from "../data/HeadData"
import PageHeaderColorBg from "../headers/PageHeaderColorBg"
import HeaderNav from "../navigation/HeaderNav"
import htmlSerializer from '../utils/htmlSerializer'

import CanIcon from '../assets/can58ec8bb8.svg'

export const query = graphql`
  query BlogPostQuery($uid: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismic {
      allBlog_posts(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
            }
            title
            author
            date_published
            body
          }
        }
      }
    }
  }
`

export default function BlogPost({ data }) {
  const prismicContent = data.prismic.allBlog_posts.edges.slice(0, 1).pop()
  if (!prismicContent) return null
  const doc = prismicContent.node

  const siteData = data.site.siteMetadata
  if (!siteData) return null

  const date = Date(doc.date_published)
  const formattedDate = Intl.DateTimeFormat('en-US',{
    year: 'numeric',
    month: 'short',
    day: '2-digit' }).format(date)
  const noDate = Date(doc._meta.firstPublicationDate)
  const formattedNoDate = Intl.DateTimeFormat('en-US',{
    year: 'numeric',
    month: 'short',
    day: '2-digit' }).format(noDate)

  return (
    <HomeContainer>
      <HeadData title={doc.title ? RichText.asText(doc.title) : 'The Blog Title'} />

      <PageHeaderColorBg>
        <HeaderNav isHome />

        <div className='sm:w-11/12 md:w-11/12 lg:w-8/12 xl:w-6/12 flex flex-col items-center mx-auto'>
          <CanIcon className='mt-20 sm:mt-12 w-12 h-12 opacity-50' />
          <h1 className='text-4xl sm:text-2xl text-center font-bold text-grolens-green-900 mt-8 leading-tight'>{doc.title ? RichText.asText(doc.title) : 'The Blog Title'}</h1>
          <p className='text-center sm:text-sm text-grolens-green-600 mt-3'>Written by <b>{doc.author ? doc.author : 'GroLens'}</b> on <b>{date ? formattedDate : formattedNoDate}</b></p>
        </div>
      </PageHeaderColorBg>

      <div className='blog-body sm:w-11/12 md:w-11/12 lg:w-6/12 xl:w-4/12 mx-auto py-16 sm:py-12'>
        {doc.body
          ?
          <RichText
            render={doc.body}
            htmlSerializer={htmlSerializer}
          />
          :
          `If you're seeing this, the author probably forgot to include the content for this post.`
        }
      </div>

      <div className='sm:w-11/12 md:w-11/12 lg:w-6/12 xl:w-4/12 mx-auto mb-12'>
        <h3 className='font-bold text-grolens-green-600'>Share this post:</h3>

        <div>
          <Twitter link={`${siteData.siteUrl}/blog/${doc._meta.uid}`} />
          <Facebook link={`${siteData.siteUrl}/blog/${doc._meta.uid}`} />
        </div>
      </div>

      <div className='sm:w-11/12 md:w-11/12 lg:w-6/12 xl:w-4/12 mx-auto mb-12'>
        <Disqus />
      </div>

      <CTASection />
    </HomeContainer>
  )
}