import React from 'react'
import { Elements } from 'prismic-richtext'
import { linkResolver } from './linkResolver'
import { Link as PrismicLink } from 'prismic-reactjs'
import { Link } from "gatsby"

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer
const htmlSerializer = function(type, element, content, children, key) {
  var props = {}

  switch(type) {

    case Elements.heading2: // Heading 2
      props = {className: 'text-2xl sm:text-xl text-grolens-green-900 mt-6 mb-3'}
      return React.createElement('h2', propsWithUniqueKey(props, key), children)

    case Elements.heading3: // Heading 3
      props = {className: 'text-xl sm:text-lg text-grolens-green-900 mt-6 mb-3'}
      return React.createElement('h3', propsWithUniqueKey(props, key), children)

    case Elements.paragraph: // Paragraph
      props = {className: 'text-lg sm:text-base text-grolens-green-900 mb-3'}
      return React.createElement('p', propsWithUniqueKey(props, key), children)

    case Elements.listItem: // Unordered List Item
      props = {className: 'text-lg sm:text-base text-grolens-green-900 mb-2'}
      return React.createElement('li', propsWithUniqueKey(props, key), children)

    case Elements.oListItem: // Ordered List Item
      props = {className: 'text-lg sm:text-base text-grolens-green-900 mb-2'}
      return React.createElement('li', propsWithUniqueKey(props, key), children)

    case Elements.list: // Unordered List
      props = {className: 'list-disc ml-8 mt-6 mb-6'}
      return React.createElement('ul', propsWithUniqueKey(props, key), children)

    case Elements.oList: // Ordered List
      props = {className: 'list-decimal ml-8 mt-6 mb-6'}
      return React.createElement('ol', propsWithUniqueKey(props, key), children)

    case Elements.image: // Image
      const linkUrl = element.linkTo ? element.linkTo.url || linkResolver(element.linkTo) : null
      const linkTarget = (element.linkTo && element.linkTo.target) ? { target: element.linkTo.target } : {}
      const linkRel = linkTarget.target ? { rel: 'noopener' } : {}
      const img = React.createElement('img', { src: element.url , alt: element.alt || '' })
      return React.createElement(
        'p',
        propsWithUniqueKey({ className: [element.label || '', 'block-img mt-6 mb-6'].join(' ') }, key),
        linkUrl ? React.createElement('a', Object.assign({ href: linkUrl }, linkTarget, linkRel), img) : img
      )

    case Elements.embed: // Embed
      props = Object.assign({
        "data-oembed": element.oembed.embed_url,
        "data-oembed-type": element.oembed.type,
        "data-oembed-provider": element.oembed.provider_name,
      }, element.label ? {className: element.label} : {className: 'embed_responsive_padding'})
      const embedHtml = React.createElement('div', {dangerouslySetInnerHTML: {__html: element.oembed.html}})
      return React.createElement('div', propsWithUniqueKey(props, key), embedHtml)

    case Elements.hyperlink: // Hyperlinks
      let result = ''
      const url = PrismicLink.url(element.data, linkResolver)
      if (element.data.link_type === 'Document') {
        result = <Link to={ url } key={ key } className='underline text-grolens-green-800'>{ content }</Link>
      } else {
        const targetAttr = element.data.target ? { target: element.data.target } : {}
        const relAttr = element.data.target ? { rel: 'noopener' } : {}
        props = Object.assign({
          href: element.data.url || linkResolver(element.data),
          className: 'underline text-grolens-green-800'
        }, targetAttr, relAttr)
        result = React.createElement('a', propsWithUniqueKey(props, key), children)
      }
      return result

    default: // Always include a default that returns null
      return null
  }
}

export default htmlSerializer